import { computed, inject, Injectable, signal } from "@angular/core"
import { User } from "@angular/fire/auth"
import { collection, doc, Firestore, onSnapshot, setDoc, Unsubscribe } from "@angular/fire/firestore"
import { debounceSignal } from "@ddtmm/angular-signal-generators"

export type BaseProfile = {
  userName: string
  userId: string
  userEmail: string
  loginProvider: string
}

@Injectable({
  providedIn: "root",
})
export class FirebaseAuthProfileService {
  private firestore = inject(Firestore)

  private _profile = signal<BaseProfile | undefined>(undefined)
  profile = this._profile.asReadonly()
  saveCount = signal(0)
  saveQueue = debounceSignal<BaseProfile | undefined>(undefined, 3000)
  preSaving = signal(false)

  allUserAccounts_Unsubscribe: Unsubscribe | undefined
  private _userAccounts_map = signal<Map<string, { profile: BaseProfile }>>(new Map())
  userProfiles_map = computed(() => new Map(Array
    .from(this._userAccounts_map().entries())
    .map(([key, value]) => [key, value.profile]),
  ))

  userAccountUnsubscribe: Unsubscribe | undefined = undefined

  setProfile(profile: BaseProfile | undefined) {
    this._profile.set(profile)
  }

  clearProfile() {
    this._profile.set(undefined)
  }

  userAccountSubscribe(afUser: User) {
    if (this.userAccountUnsubscribe) {
      this.userAccountUnsubscribe()
      this.userAccountUnsubscribe = undefined
    }

    this.userAccountUnsubscribe = onSnapshot(
      doc(this.firestore, "user-accounts/" + afUser.uid),
      (docSnapshot) => {
        const userAccountDoc = docSnapshot.data() as { profile: BaseProfile } | undefined
        const profile = userAccountDoc?.profile
        const emailRegex = /@[a-zA-Z0-9.-]+/g
        this.setProfile(profile && {
          ...profile,
          userName: profile.userName.replace(emailRegex, ""),
        } || undefined)
        /**
         * check profile
         * add required elements if missing or not valid
         */
        if (!profile
          || !profile.userId
          || !profile.userName
          || !profile.userEmail
          || !profile.loginProvider
          || profile.loginProvider !== afUser.providerData[0].providerId
        ) {
          this.saveProfile({
            userId: profile?.userId || afUser.uid,
            userName: profile?.userName || afUser.displayName || afUser.email || afUser.uid,
            userEmail: profile?.userEmail || afUser.email || afUser.uid,
            loginProvider: afUser.providerData[0].providerId,
          })
        }
      },
      error => {
        console.log(error)
        console.log("Connection has timed out. Please improve your connection or try again later.")
        this.clearProfile()
      })
  }

  saveProfile(profile: BaseProfile) {
    this.saveCount.update(saveCount => saveCount + 1)
    setDoc(
      doc(collection(this.firestore, "user-accounts"), profile.userId),
      { profile },
      { merge: true },
    )
      .then(() => {
        const saveCount = this.saveCount() - 1
        this.saveCount.set(saveCount)
        if (!saveCount) {
          this.saveQueue.set(undefined)
          this.preSaving.set(false)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  subscribeToAllUserAccounts() {
    if (this.allUserAccounts_Unsubscribe) {
      this.allUserAccounts_Unsubscribe()
      this.allUserAccounts_Unsubscribe = undefined
    }
    this.allUserAccounts_Subscription()
  }

  private allUserAccounts_Subscription() {
    this.allUserAccounts_Unsubscribe = onSnapshot(
      collection(this.firestore, "user-accounts"),
      (snapshot) => {
        const userAccounts_map = new Map(this._userAccounts_map())
        snapshot.docChanges().forEach(change => {
          const userAccount = change.doc.data() as { profile: BaseProfile }
          const emailRegex = /@[a-zA-Z0-9.-]+/g
          userAccounts_map.set(userAccount.profile.userId, {
            ...userAccount,
            profile: {
              ...userAccount.profile,
              userName: userAccount.profile.userName.replace(emailRegex, ""),
            },
          })
        })
        /**
         * TODO: should we check the profile object (for userId, userName, userEmail) right away?
         */
        this._userAccounts_map.update(() => userAccounts_map)
      },
    )
  }

}
