import { Timestamp } from "@angular/fire/firestore"
import { BaseProfile } from "../../data-access/firebase-auth-profile.service"

export interface Profile extends BaseProfile {
  birthYear?: number
  educations?: Education[]
  genders?: Gender[]
  homeLocation?: string
  householdIncomeRanges?: HouseholdIncomeRange[]
  issues?: string[] // Issues/ Topics of Interest
  profileImage?: ProfileImage
  pronouns?: string[]
  races?: Race[]
  shortBio?: string // Short Bio (500 character limit)
  sleepLocations?: SleepLocation[]
  // socialMedia: string // [Link Social Media accounts!]
  title?: string
  userName: string
  userId: string
  userEmail: string
  loginProvider: string
}

export type ProfileImage = {
  fileName: string
  filePath: string
  lastUpdated: Timestamp
}

export enum ProfileField {
  "birthYear" = "birthYear",
  "educations" = "educations",
  "genders" = "genders",
  "homeLocation" = "homeLocation",
  "householdIncomeRanges" = "householdIncomeRanges",
  "issues" = "issues",
  "profileImage" = "profileImage",
  "pronouns" = "pronouns",
  "races" = "races",
  "shortBio" = "shortBio",
  "sleepLocations" = "sleepLocations",
  "title" = "title",
  "userName" = "userName",
}
/*
export type ProfileField =
  | "userName"
  | "homeLocation"
  | "birthYear"
  | "title"
  | "profileImage"
  | "pronouns"
  | "issues"
  | "shortBio"
  | "races"
  | "genders"
  | "householdIncomeRanges"
  | "educations"
  | "sleepLocations"
*/

export type ProfileStatus = {
  percent: number
  progress: ProfileProgress
  type: string
}

export enum ProfileProgress {
  INCOMPLETE = "INCOMPLETE",
  REQUIRED = "REQUIRED",
  COMPLETE = "COMPLETE",
}

export enum Race {
  ASIAN = "ASIAN",
  AFRICAN_AMERICAN = "AFRICAN_AMERICAN",
  FIRST_NATIONS = "FIRST_NATIONS",
  NATIVE_CENTRAL_OR_SOUTH_OR_ALASKA = "NATIVE_CENTRAL_OR_SOUTH_OR_ALASKA",
  NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER = "NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER",
  WHITE = "WHITE",
  OTHER = "OTHER",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  TRANS = "TRANS",
  NON_BINARY = "NON_BINARY",
  GENDER_QUEER = "GENDER_QUEER",
}

export enum HouseholdIncomeRange {
  LESS_THAN_20K = "LESS_THAN_20K",
  BETWEEN_20K_45K = "BETWEEN_20K_45K",
  BETWEEN_45K_140K = "BETWEEN_45K_140K",
  BETWEEN_140K_150K = "BETWEEN_140K_150K",
  BETWEEN_150K_200K = "BETWEEN_150K_200K",
  ABOVE_200K = "ABOVE_200K",
}

export enum Education {
  MASTERS = "MASTERS",
  BACHELORS = "BACHELORS",
  ASSOCIATES = "ASSOCIATES",
  POST_SECONDARY = "POST_SECONDARY",
  SOME_COLLEGE = "SOME_COLLEGE",
  HIGH_SCHOOL = "HIGH_SCHOOL",
  NONE = "NONE",
}

export enum SleepLocation {
  EMERGENCY_TRANSITIONAL = "EMERGENCY_TRANSITIONAL",
  INSTITUTION = "INSTITUTION",
  UNHOUSED = "UNHOUSED",
  RENTER = "RENTER",
  HOMEOWNER = "HOMEOWNER",
  SUBSIDIZED = "SUBSIDIZED",
}
