import { Component, computed, inject, input, output } from "@angular/core"
import { RouteService } from "../../services/route.service"
import { EditorContext, EditorService } from "./services/editor.service"
import { ProfileService } from "../profile/profile.service"
import { FirestoreReadService } from "../content/firestore-read.service"
import { SectionEnum } from "../shared/fields/fields.type"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    <div class="row-nowrap items-start">
<!--
      <button
        class="btn btn-sm blue"
        [disabled]="saving() || preSaving()"
        (click)="close.emit()"
      >
        Close
      </button>
-->

<!--
      @if (!autosave() && !moderationContext()) {
        @if (!saving()) {
          <button
            class="btn btn-sm blue"
            (click)="saveAndClose.emit()"
          >
            Save
          </button>
        }
        @if (saving()) {
          <button class="btn btn-sm blue">
            <lib-ui-icon
              [icon]="faYinYang"
              [style.font-size.px]="15"
              [style.padding-right.px]="0"
              animation="spin"
            />
          </button>
        }
      }
-->
      
      <div class="w-full center">
        Share Your Story
<!--        {{ title() }}-->
      </div>
      
      
<!--
      @if (contributorContext()) {
        @if (needsChanges()) {
          <button class="btn btn-sm red">
            Needs Changes
          </button>
        }
        @if (!needsChanges()) {
          <button class="btn btn-sm gray">
            Ready for Review
          </button>
        }
      }
-->

<!--
      @if (adminContext()) {
        <lib-ui-confirm
          [options]="{ triggerButtonText: 'Delete', icon: faTrash }"
          (confirmed)="deleteAndClose.emit()"
        />
      }
-->

    </div>
  `,
  selector: "e2e-editor-header",
})
export class EditorHeaderComponent {
  private routeService = inject(RouteService)
  private editorService = inject(EditorService)
  private profileService = inject(ProfileService)
  private firestoreReadService = inject(FirestoreReadService)

  context = input.required<EditorContext>()

  // close = output()
  saveAndClose = output()
  // approveAndClose = output()
  // reject = output()
  deleteAndClose = output()

  adminContext = computed(() => this.context() === "admin")
  contributorContext = computed(() => this.context() === "contributor")
  moderationContext = computed(() => this.context() === "moderation")

  // section = this.routeService.section
  autosave = this.editorService.autosave
  saving = this.editorService.saving
  preSaving = this.editorService.preSaving
  needsChanges = this.editorService.needsChanges
  isEditor = this.profileService.isEditor
  content = computed(() => this.editorService.editorContent())

  story = computed(() => {
    const content = this.content()
    return content && this.firestoreReadService.storyFromContent(content)
  })
  title = computed(() => this.story()?.[SectionEnum.TITLE].value || "Share Your Story")

  resubmitForReview() {
    this.editorService.resubmitForReview()
  }

  /*
    saveAndClose() {
      this.editorService.saveAndClose()
    }

    approveAndClose() {
      this.editorService.approveAndClose()
    }

    closeEditor() {
      this.editorService.closeEditor()
    }

    reject() {
      this.editorService.reject()
    }

    deleteContent(content: Content | undefined) {
      if (content) {
        this.editorService.deleteContent(content)
        if (this.section().TIMELINE) {
          // this.routeService.removeFragment()
        }
      }
    }
  */

}
