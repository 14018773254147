import { Classification, ContentType, LngLat, PublishStatus, Story } from "../content/content.model"
import { computed, inject, Injectable, signal } from "@angular/core"
import { ProfileService } from "../profile/profile.service"
import { FirestoreReadService } from "../content/firestore-read.service"
import { RouteService } from "../../services/route.service"
import { RegionProperties, StoryFeature, StoryProperties } from "./map.type"
import { regionData } from "../../regions/region.model"
import { EditorService } from "../editor/services/editor.service"
import { UtilsService } from "@shared"
import { sort } from "util/sort"
import { SectionEnum } from "../shared/fields/fields.type"
import { FeatureCollection } from "geojson"

@Injectable({
  providedIn: "root"
})
export class MapService {
  private editorService = inject(EditorService)
  private profileService = inject(ProfileService)
  private firestoreReadService = inject(FirestoreReadService)
  private routeService = inject(RouteService)
  private utilsService = inject(UtilsService)

  debug = this.routeService.debug

  underwritingManual = this.routeService.underwritingManual

  loggedIn = this.profileService.loggedIn
  isContributor = this.profileService.isContributor

  saving = this.editorService.saving

  region = this.routeService.region
  center = computed(() => regionData[this.region()]?.location)
  private _zoomLevel = signal<number | undefined>(undefined)
  zoomLevel = computed(() =>
    this._zoomLevel() || regionData[this.region()]?.zoom || 4)

  private _markerLocation = signal<LngLat | undefined>(undefined)
  markerLocation = this._markerLocation.asReadonly()
  private _storyProperties = signal<StoryProperties>({ authorUserName: "", storyId: "", text: "", filePath: "" })
  storyProperties = this._storyProperties.asReadonly()
  storyText = signal("")

  // mode = this.routeService.localMapsMode
  // learnMode = computed(() => this.mode() === LocalMapsMode.LEARN)
/*
    return [...this.publishedAndMyMapStories_array(), ...this.myMapStories_array()]
    switch (this.mode()) {
      case LocalMapsMode.DISCOVER:
        return this.publishedAndMyMapStories_array()
      case LocalMapsMode.STORY:
        return this.myMapStories_array()
      case LocalMapsMode.LEARN:
        return []
      default:
        if (this.editorService.creatingContributedLocalMap_flag()) {
          const story = this.editorService.editorContent()
          if (story) {
            // return [story]
          }
        }
        return []
    }
  discoverMarkers = computed(() => {
  })
*/
  private _regionProperties = signal<RegionProperties | null>(null)
  regionProperties = this._regionProperties.asReadonly()

  private allLocalMapStories = computed(() => this.firestoreReadService.arrayFromStories()
    .filter(content => content.status.contentTypes.includes(ContentType.LOCAL_MAP))
    .sort((a, b) => sort(a.settings.title, b.settings.title))
  )

  private publishedMapStories = computed(() => this.allLocalMapStories()
    .filter(content => content.status.publishStatus === PublishStatus.PUBLISHED)
    // .filter(content => content.created.userId !== this.profileService.userId())
  )
  private contributedMapStories = computed(() => this.publishedMapStories()
    .filter(content => content.status.classification === Classification.CONTRIBUTED)
  )
  private featuredMapStories = computed(() => this.publishedMapStories()
    .filter(content => content.status.classification === Classification.FEATURED)
  )
  private regionalMapStories = computed(() => this.publishedMapStories()
    .filter(content => content.status.classification === Classification.REGIONAL)
  )

  private myMapStories = computed(() => this.allLocalMapStories()
    .filter(content => content.created.userId === this.profileService.userId())
  )

  setMarkerLocation(location: LngLat | undefined) {
    this._markerLocation.set(location)
  }

  setStoryProperties(storyProperties: StoryProperties) {
    this._storyProperties.set(storyProperties)
  }

  unsetStoryProperties() {
    this._storyProperties.set({ authorUserName: "", storyId: "", text: "", filePath: "" })
  }

  setRegionProperties(regionProperties: RegionProperties) {
    this._regionProperties.set(regionProperties)
  }

  unsetRegionProperties() {
    this._regionProperties.set(null)
  }

  closeModal() {
    this.routeService.changeRoute([])
  }

  newStory(markerLocation: LngLat) {
    const storyText = this.storyText()
    // return this.editorService.newLocalMapStory(storyText, markerLocation)
  }

  setZoomLevel(zoomLevel: number) {
    this._zoomLevel.set(zoomLevel)
  }

  publishedStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this
      .publishedMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))
  contributedStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this
      .contributedMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))
  featuredStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this
      .featuredMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))
  regionalStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this
      .regionalMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))




  myStoriesMarkerCollection = computed<FeatureCollection>(() => ({
    type: "FeatureCollection" as FeatureCollection["type"],
    features: this
      .myMapStories()
      .map((story) => this.getFeatureFromStory(story))
      .filter((storyMarkerFeature): storyMarkerFeature is StoryFeature => Boolean(storyMarkerFeature)),
  }))

  // create a feature based on content
  getFeatureFromStory(story: Story | undefined): StoryFeature | undefined {
    const location = story?.settings.location
    if (location?.lng && location?.lat && story) {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location.lng, location.lat],
        },
        properties: {
          filePath: story[SectionEnum.IMAGE].filePath,
          authorUserName: story.created.userName,
          storyId: story.id,
          text: story[SectionEnum.BODY].value,
        },
      }
    }
    return
  }

}
