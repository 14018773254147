import { Component, inject, input } from "@angular/core"
import { UserSignInProviderButtonComponent } from "./user-sign-in-provider-button.component"
import { UserSignInWithPasswordComponent } from "./user-sign-in-with-password.component"
import { UserLoginService } from "./user-login.service"

@Component({
  imports: [
    UserSignInProviderButtonComponent,
    UserSignInWithPasswordComponent,
  ],
  standalone: true,
  template: `
    <div class="row" style="justify-content: center">
      <div>
        Sign in with
      </div>
      @for (providerType of providerTypes(); track providerType; let last = $last) {
        <e2e-sign-in-provider-button [providerType]="providerType"/>
        @if (!last) {
          or
        }
      }

    </div>

    <div>
    </div>


    @if (signInWithPassword()) {
      <div class="center">
        Sign in with Email and Password
      </div>
      <e2e-sign-in-with-password/>
      <hr>
    }

  `,
  selector: "e2e-sign-in",
})
export class UserSignInComponent {
  private featureSharedLoginService = inject(UserLoginService)

  providerTypes = this.featureSharedLoginService.providerTypes
  signInWithPassword = input.required<boolean>()
}
