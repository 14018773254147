import { Component, computed, inject, input, signal } from "@angular/core"
import { base64ToFile, ImageCroppedEvent, ImageCropperComponent, ImageTransform, LoadedImage } from "ngx-image-cropper"
import { FieldType, Image, ImageFileType } from "../fields.type"
import { EditorService } from "../../../editor/services/editor.service"
import { ref, Storage, uploadBytesResumable } from "@angular/fire/storage"
import { StorageService } from "../../../../services/storage.service"

/**
 * TODO:
 *   consider bucket rule change that would allow overwriting a file (useful when resizing or cropping an existing image)
 */

@Component({
  imports: [
    ImageCropperComponent,
  ],
  standalone: true,
  template: `
    <div class="row-center">
      <button
        class="btn btn-sm blue defaultLabel"
      >
        <label
          style="padding: 0.5rem 1rem; height: 100%; cursor: pointer"
          [for]="fileInputId()"
        >
          Choose File
        </label>
      </button>
      <image-cropper
        [imageURL]="cropperImageUrl()"
        format="jpeg"
        [imageChangedEvent]="imageChangedEvent()!"
        [maintainAspectRatio]="false"
        [resizeToWidth]="minWidth"
        [onlyScaleDown]="true"
        [transform]="transform"
        [hideResizeSquares]="true"
        [imageQuality]="100"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      />
    </div>
    <input
      style="display: none"
      [id]="fileInputId()"
      type="file"
      accept="image/png, image/jpeg, image/gif"
      [disabled]="saving()"
      (change)="fileChangeEvent($event)"
    >
  `,
  selector: "e2e-image-cropper-form",
})
export class ImageCropperFormComponent {
  private storage = inject(Storage)
  private editorService = inject(EditorService)
  private storageService = inject(StorageService)

  saving = this.editorService.saving

  rowId = input.required<string>()
  // editorContent = this.editorService.editorContent
  // formGroup: FormGroup<ContentForm> | undefined
  // imageFormGroup: FormGroup<ImageForm> | undefined
  // contentWithRowId: string | undefined
  fileInputId = computed(() => ["file-input", this.editorService.editorContent()?.id, this.rowId()].join("-"))

  cropper = false
  cropperImageUrl = computed<string>(() => {
    return this.storageService.getFileUrl((this.editorService.editorContent()?.rows[this.rowId()] as Image)?.filePath)
    },
  )
  fileType: ImageFileType = ""
  // formFileType: FileType = ""
  fileOriginal: File | undefined

  imageChangedEvent = signal<Event | undefined>(undefined)
  croppedImage: File | undefined
  croppedImageFile: Blob | undefined
  // file: Blob
  // compressedFile: File
  transform: ImageTransform = {}

  // ref: AngularFireStorageReference
  // task: AngularFireUploadTask | undefined
  // fileSrc: string
  // fileName: string
  // filePath: string
  // fileUrl: string
  // storageRef: FirebaseStorageRef
  // uploadProgress: { percent: number } = { percent: 0 }

  minWidth = 500
  // formFilePath: string
  newFilePath = this.storageService.newImageFilePath

  /**
   * cropper methods
   */

  fileChangeEvent(event: Event): void {
    // console.log(event)
    // console.log(event)
    const target = event.target as HTMLInputElement
    const files = target.files
    // console.log(files)
    if (files?.length) {
      const file = files[0]
      const fileType = file.type as ImageFileType
      /**
       * only use the first file even if multiple are uploaded to the browser
       * check that it is of FileType
       */
      const cropperFileTypes: ImageFileType[] = ["image/jpeg", "image/png", "image/gif"]
      if (cropperFileTypes.includes(fileType)) {
        this.imageChangedEvent.set(event)
        this.fileType = fileType
        this.fileOriginal = file
        this.applyChanges()
        console.log("fileChangeEvent()", file)
      }
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    if (event.blob) {
      this.croppedImageFile = event.blob as File
    }
    if (event.base64) {
      this.croppedImageFile = base64ToFile(event.base64 as string) as File
    }
    // console.log("imageCropped()", event)
    this.applyChanges()
    // this.croppedImage = event.base64
    // this.compressAndStoreImage(base64ToFile(event.base64))
  }

  imageLoaded(image: LoadedImage): void {
    // console.log("imageLoaded()", image)
    // console.log(image)
    // console.log(base64ToFile(image.original.base64).type)
    // show cropper
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
    // show message
  }

  /**
   * helper methods
   */

  applyChanges(): void {
    // console.log(this.croppedImageFile)
    if (this.croppedImageFile) {
      // console.log("applyChanges()")
      this.compressImage(this.croppedImageFile).then((compressedImage) => {
        this.storeImage(compressedImage as File)
      })
    }
  }

  compressAndStoreImage(imageFile: Blob): void {
    this.compressImage(imageFile).then((compressedImage) => {
      this.storeImage(compressedImage as File)
    })
  }

  async compressImage(imageFile: Blob): Promise<File | undefined> {
    /**
     * // you should provide one of maxSizeMB, maxWidthOrHeight in the options
     * const options = {
     *   maxSizeMB: number,          // (default: Number.POSITIVE_INFINITY)
     *   maxWidthOrHeight: number,   // compressedFile will scale down by ratio to a point that width or height
     *                               // is smaller than maxWidthOrHeight (default: undefined)
     *                               // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
     *                               // Please check the Caveat part for details.
     *   onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
     *   useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
     *
     *   // following options are for advanced users
     *   maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
     *   exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
     *   fileType: string,           // optional, fileType override
     *   initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
     }

     imageCompression(file: File, options): Promise<File>

     */

      // const imageFile = event.target.files[0];
      // console.log("originalFile instanceof Blob", imageFile) // true
      // console.log("originalFile size " + (imageFile.size / 1024).toFixed(2) + " KB")
      // return imageFile as File

    const options = {
        initialQuality: 0.8,
        fileType: this.fileType,
        maxIteration: 50,
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }
    try {
      return imageFile as File
      // console.log(this.fileType)
      // const compressedImage = await imageCompression(imageFile as File, options)
      // console.log("compressedFile size " + (compressedImage.size / 1024).toFixed(2) + " KB") // smaller than maxSizeMB
      // return compressedImage
    } catch (e) {
      console.log(e)
    }
    /*
          .then((compressedFile) => {
            // console.log("compressedFile instanceof Blob", compressedFile instanceof Blob) // true
            console.log("compressedFile size " + (compressedFile.size / 1024).toFixed(2) + " KB") // smaller than maxSizeMB
            return compressedFile
          })
          .catch(function (error) {
            console.log(error.message)
          })
    */
    return
  }

  storeImage(storableImage: File): void {
    let imageToStore = storableImage
    if (imageToStore) {
      if (this.fileType === "image/gif" && this.fileOriginal) {
        imageToStore = this.fileOriginal
      }
      /**
       * Upload image to storage.
       */
      const newFilePath = this.newFilePath
      if (newFilePath) {
        const storageReference = ref(this.storage, newFilePath)
        uploadBytesResumable(storageReference, imageToStore).then((snapshot) => {
          if (snapshot) {
            if (snapshot.state === "success") {
              const content = this.editorService.editorContent()
              if (content?.rows[this.rowId()]?.field === FieldType.IMAGE) {
                this.editorService.queueEditor({
                  ...content,
                  rows: {
                    ...content.rows,
                    [this.rowId()]: {
                      ...content.rows[this.rowId()],
                      filePath: newFilePath,
                      fileType: this.fileType || (content.rows[this.rowId()] as Image).fileType,
                      svgGraphics: null
                    } as Image,
                  },
                })
              }
            }
          }
        })
      }
    }
  }

  /**
   * template methods
   */

  flipHorizontal(): void {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    }
  }
}
