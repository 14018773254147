import { Component, computed, inject, input, signal } from "@angular/core"
import { UserLoginService } from "./user-login.service"
import { UtilAuthProviderService, ProviderType } from "util/auth-provider"

@Component({
  standalone: true,
  template: `
    <div style="text-align: center">
      <button
        class="btn"
        [class]="buttonClass()"
        (click)="signIn()"
      >
        <div class="row" style="justify-content: center">
          <div>
            {{ providerLabel() }}
          </div>
        </div>
      </button>
    </div>
  `,
  selector: "e2e-sign-in-provider-button",
})
export class UserSignInProviderButtonComponent {
  private loginService = inject(UserLoginService)
  private authProviderService = inject(UtilAuthProviderService)

  providerType = input.required<ProviderType>()
  passwordSelected = signal(false)
  buttonClass = computed(() => this.passwordSelected() ? "btn-primary" : "")

  provider = computed(() =>
    this.authProviderService.provider(this.providerType()),
  )

  providerLabel = computed(() => this.provider()?.label)
  providerSignIn = computed(() => this.provider()?.signIn)

  signIn(): void {
    switch (this.providerSignIn()) {
      case "withPassword":
        this.passwordSelected.set(!this.loginService.withPassword_flag())
        this.loginService.withPassword_flag.set(!this.loginService.withPassword_flag())
        break
      case "withPopup":
        this.loginService.withPassword_flag.set(false)
        this.loginService.withPopup(this.providerType())
    }
  }
}
