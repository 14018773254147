import { inject, Injectable, signal } from "@angular/core"
import { Grade, IntroGradeData, IntroLegacyCardData, IntroSubContent, LegacyForTodayCard } from "./intro.model"
import { RegionService } from "../../../regions/region.service"
import { RouteService } from "../../../services/route.service"
import { StorageService } from "../../../services/storage.service"

@Injectable({
  providedIn: "root",
})
export class IntroService {
  private routeService = inject(RouteService)
  private regionService = inject(RegionService)
  private storageService = inject(StorageService)

  fragments = this.routeService.fragments
  localVideo = this.regionService.localVideo

  logos = signal<IntroSubContent[]>([
    {
      contents: [],
      style: {},
      type: "video/webm",
      image: {
        src: "/assets/pages/intro/logo/image-1-UN.png",
        style: {},
      },
      video: {
        src: this.storageService.getFileUrl("/assets/pages/intro/logo/video-1-UN.webm"),
        type: "video/webm",
      },
    },
    {
      contents: [],
      style: {},
      type: "video/webm",
      image: {
        src: "/assets/pages/intro/logo/image-2-DESIGN.png",
        style: {},
      },
      video: {
        src: this.storageService.getFileUrl("/assets/pages/intro/logo/video-2-DESIGN.webm"),
        type: "video/webm",
      },
    },
    {
      contents: [],
      style: {},
      type: "video/webm",
      image: {
        src: "/assets/pages/intro/logo/image-3-THE.png",
        style: {},
      },
      video: {
        src: this.storageService.getFileUrl("/assets/pages/intro/logo/video-3-THE.webm"),
        type: "video/webm",
      },
    },
    {
      contents: [],
      style: {},
      type: "video/webm",
      image: {
        src: "/assets/pages/intro/logo/image-4-RED.png",
        style: {},
      },
      video: {
        src: this.storageService.getFileUrl("/assets/pages/intro/logo/video-4-RED.webm"),
        type: "video/webm",
      },
    },
    {
      contents: [],
      style: {},
      type: "video/webm",
      image: {
        src: "/assets/pages/intro/logo/image-5-LINE.png",
        style: {},
      },
      video: {
        src: this.storageService.getFileUrl("/assets/pages/intro/logo/video-5-LINE.webm"),
        type: "video/webm",
      },
    },
  ])

  grades = signal<IntroGradeData[]>([
    {
      grade: Grade.GRADE_A,
      imageDescription: {
        altText: "",
        src: "/assets/pages/intro/map-zones/description-grade-a-green.jpg",
      },
      imageMap: {
        map_src: "/assets/pages/intro/map-zones/grade-a-green-map.png",
        zone_src: "/assets/pages/intro/map-zones/grade-a-green-zone.png",
        altText: "Map Zone - Grade A Green",
        style: {
          width: "75%",
        },
        width: 200,
        height: 409,
      },
      imagePicture: {
        altText: "",
        src: "/assets/pages/intro/map-zones/image-grade-a-green.jpg",
        style: {
          transform: "rotate(12deg)",
        },
      },
      style: {
        transform: "rotate(-16deg)",
      },
      title: "GREEN: GRADE A",
      copyArray: [
        "GRADE A: Green areas were considered to be the most desirable, and always were home to those at the top of a false idea of racial hierarchy.",
        "These were areas that often had strict restrictions on who could live in the area, and these were enforced legally with neighborhood covenants or in the deeds of the homes. When these restrictions became illegal in 1968, racial steering, single family zoning and intimidation were often used to preserve ‘homogeneity.’ Green areas were usually exclusively wealthy neighborhoods as well, with many area descriptions throughout the country describing the inhabitants simply as “capitalists.",
      ],
    },
    {
      grade: Grade.GRADE_B,
      imageDescription: {
        altText: "",
        src: "/assets/pages/intro/map-zones/description-grade-b-blue.jpg",
      },
      imageMap: {
        map_src: "/assets/pages/intro/map-zones/grade-b-blue-map.png",
        zone_src: "/assets/pages/intro/map-zones/grade-b-blue-zone.png",
        altText: "Map Zone - Grade B Blue",
        style: {
          width: "75%",
        },
        width: 200,
        height: 281,
      },
      style: {
        transform: "rotate(-25deg)",
      },
      imagePicture: {
        altText: "",
        src: "/assets/pages/intro/map-zones/image-grade-b-blue.jpg",
        style: {
          transform: "rotate(0deg)",
        },
      },
      title: "BLUE: GRADE B",
      copyArray: [
        "GRADE B: Blue areas were second best. They were often still homogeneously White and considered ‘desirable’ if not the most wealthy areas.",
        "Blue areas didn’t make the cut to be green. What makes an area green is not just to say; yes this is where White people are living. Often, a green rating also required the presence of restrictions preventing the ‘infiltration’ of ‘hazardous’ people. White areas without these restrictions, or that were too close to ‘hazardous’ neighborhoods, were often colored in blue: second best. Over time, some blue areas slipped into decline as well, while others retained their value. This often depended on the greater context of the surrounding areas.",
      ],
    },
    {
      grade: Grade.GRADE_C,
      imageDescription: {
        altText: "",
        src: "/assets/pages/intro/map-zones/description-grade-c-yellow.jpg",
      },
      imageMap: {
        map_src: "/assets/pages/intro/map-zones/grade-c-yellow-map.png",
        zone_src: "/assets/pages/intro/map-zones/grade-c-yellow-zone.png",
        altText: "Map Zone - Grade C Yellow",
        style: {
          width: "65%",
        },
        width: 200,
        height: 490,
      },
      imagePicture: {
        altText: "",
        src: "/assets/pages/intro/map-zones/image-grade-c-yellow.jpg",
        style: {
          transform: "rotate(0deg)",
        },
      },
      style: {
        transform: "rotate(10deg)",
      },
      title: "YELLOW: GRADE C",
      copyArray: [
        "GRADE C: Yellow areas were deemed to be in decline, and often didn’t fare much better than their redlined counterparts.",
        "The maps and area descriptions placed ‘negroes’ at the bottom of a false racial hierarchy. But even for those ‘one rung up,’ the maps also devalued many groups of people, including Hispanic families, Asian families, and those labeled ‘foriegn born,’ which at that time included Italian immigrants, Eastern Europeans, Jewish families and others.",
        "When the idea of race eventually became primarily one of skin color and European origin, some of these groups would be re-considered as White, granting access to segregated suburbs. Meanwhile others would remain ‘people of color’ in red or yellow districts.",
      ],
    },
    {
      grade: Grade.GRADE_D,
      imageDescription: {
        altText: "",
        src: "/assets/pages/intro/map-zones/description-grade-d-red.jpg",
      },
      imageMap: {
        map_src: "/assets/pages/intro/map-zones/grade-d-red-map.png",
        zone_src: "/assets/pages/intro/map-zones/grade-d-red-zone.png",
        altText: "Map Zone - Grade D Red",
        style: {
          width: "75%",
        },
        width: 200,
        height: 409,
      },
      style: {
        transform: "rotate(-16deg)",
      },
      imagePicture: {
        altText: "",
        src: "/assets/pages/intro/map-zones/image-grade-d-red.jpg",
        style: {
          transform: "rotate(0deg)",
        },
      },
      title: "RED: GRADE D",
      copyArray: [
        "GRADE D: Once Redlined, these neighborhoods became destined for disinvestment and concentrated poverty.",
        "Redlining was destabilizing for populations who were subsequently cut off from mechanisms to join the middle-class, like home ownership and education programs like those in the G.I. Bill, which offered free higher education to returning veterans from WWII. Before the 1968 Fair Housing Act, 98% of federally backed home loans went only to White families.",
        "As we will see in the section three timeline, redlining was followed by destructive programs like Urban Renewal and Planned Shrinkage, while practices like contract buys and predatory banking exploited those who lived there. This disruption led to epidemics of drugs, disease and crime that further tore apart communities and justified the mass incarceration of young men of color.",
      ],
    },
  ])

  cards = signal<IntroLegacyCardData[]>([
    {
      image: {
        altText: "",
        src: "/assets/pages/intro/legacy/card-banking.jpg",
      },
      style: {
        marginLeft: -50,
      },
      title: LegacyForTodayCard.BANKING,
      type: {
        current: LegacyForTodayCard.BANKING,
        next: LegacyForTodayCard.HEALTH,
        previous: LegacyForTodayCard.WEALTH
      },
      copyArray: [
        {
          type: "paragraph",
          value: "It is clear that the banking system is designed to work well for the well-to-do, who are offered free banking services, while those living paycheck-to-paycheck or without an asset base are either shunned or exploited by the big banks. In fact, the largest consumer banks profited mightily off struggling customers last year, collecting about $11 billion in overdraft fees in 2015. This made up 8% of their profits, according to the New York Times and the Consumer Financial Protection Bureau.",
        },
        {
          type: "paragraph",
          value: "As a direct result of redlining, traditional bank branches tend to be concentrated in wealthier, whiter communities; the Bronx has the lowest concentration of bank branches per family of any county in the nation. Many of the check cashers, payday lenders, and online high-cost lenders that have filled this vacuum are financed by Wall Street financial institutions. For instance, JPMorgan Chase is partnering with the online lender, OnDeck Capital, known to “approve loans in a flash and charge eyepopping interest rates that averaged around 54% as of 2014.” Wells Fargo offers a Direct Deposit Advance Loan, a payday loan type product with an annual percentage rate of 120%. All of these products are designed to extract wealth from struggling families to large corporations.",
        },
      ],
    },
    {
      image: {
        altText: "",
        src: "/assets/pages/intro/legacy/card-health.jpg",
      },
      style: {
        marginLeft: 50,
      },
      title: LegacyForTodayCard.HEALTH,
      type: {
        current: LegacyForTodayCard.HEALTH,
        next: LegacyForTodayCard.POLICING,
        previous: LegacyForTodayCard.BANKING
      },
      copyArray: [
        {
          type: "paragraph",
          value: "A 2010 health study by the University of Wisconsin Population Health Institute and the Robert Wood Johnson Foundation found that while the Bronx ranked in the middle of the pack for New York Counties when it comes to behavioral health factors (diet, exercise, smoking, alcohol use), it ranked worst in the state for socioeconomic factors that affect health, such as unemployment, poverty, street crime and low education. The result is it came in last in overall wellness. In the same year, 84% of Bronx residents identified as black or latino, by far the highest in the state.",
        },
      ],
    },
    {
      image: {
        altText: "",
        src: "/assets/pages/intro/legacy/card-policing.jpg",
      },
      style: {
        marginLeft: -50,
      },
      title: LegacyForTodayCard.POLICING,
      type: {
        current: LegacyForTodayCard.POLICING,
        next: LegacyForTodayCard.WEALTH,
        previous: LegacyForTodayCard.HEALTH
      },
      copyArray: [
        {
          type: "paragraph",
          value: "According to the United States Department of Justice report on Ferguson following the shooting death of Michael Brown, African Americans “experience disparate impact in nearly every aspect of Ferguson’s law enforcement system. Despite making up 67% of the population, African Americans accounted for 85% of the Ferguson Police Department’s traffic stops, 90% of FPD’s citations, and 93% of FPD’s arrests from 2012 to 2014.” When you consider that black residents comprised 95% of all jaywalking citations between 2011 and 2013, it’s no wonder that tensions were at a peak in such a highly segregated city. Many cities, due to disinvestment and political will, make up for their shrinking tax base by cutting costs (like on water in Flint, Michigan) or by overpolicing, almost always with disastrous consequences for residents. In 2014, Ferguson’s bottom-line-driven police force issued 16,000 arrest warrants in a town of just 21,000 residents.",
        },
      ],
    },
    {
      image: {
        altText: "",
        src: "/assets/pages/intro/legacy/card-wealth.jpg",
      },
      style: {
        marginLeft: 50,
      },
      title: LegacyForTodayCard.WEALTH,
      type: {
        current: LegacyForTodayCard.WEALTH,
        next: LegacyForTodayCard.BANKING,
        previous: LegacyForTodayCard.POLICING
      },
      copyArray: [
        {
          type: "paragraph",
          value: "According to Beyond Broke, a 2014 report by the Center for Global Policy Solutions and Duke University, White people have a median net worth ($111,740) nearly 16 times that of Black people ($7,113), and over 13 times that of Latino people ($8,113).",
        },
        {
          type: "paragraph",
          value: "The 2015 joint report, Umbrellas Don’t Make it Rain, shows that this racial wealth gap cannot be explained just by education, employment, or income. Meanwhile, programs designed to ameliorate the symptoms of historic wealth destruction haven’t gone deeper to rebuild the wealth that was destroyed.",
        },
        {
          type: "paragraph",
          value: "Black families headed by a college graduate still have one-third less wealth than white families headed by a high school drop-out. Even the poorest white families have more wealth than middle income black families. Wealth disparities can best be explained by intergenerational wealth transfers (e.g., gifts,ance, college tuition, down payment assistance) deeply rooted in the homeownership opportunities of the postwar era.",
        },
        {
          type: "paragraph",
          value: "In fact, research by Dalton Conley in Being Black, Living in the Red, shows that controlling for parent’s assets makes many racial disparities in areas such as income, education, employment, disappear. In other words, intergenerational wealth has a causal relationship upon education, income and employment, even though most programs are designed based on the opposite type of relationship.",
        },
      ],
    },
  ])

}
