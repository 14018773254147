import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from "@angular/core"
import { provideRouter } from "@angular/router"
import { appRoutes } from "./app.routes"
import { NgxMapboxGLModule } from "ngx-mapbox-gl"
import { environment } from "../environments/environment"
import { getStorage, provideStorage } from "@angular/fire/storage"
import { getRemoteConfig, provideRemoteConfig } from "@angular/fire/remote-config"
import { getAuth, provideAuth } from "@angular/fire/auth"
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import { AuthTokenHttpInterceptorProvider } from "./http-interceptors/auth-token.interceptor"
import { HttpErrorInterceptorProvider } from "./http-interceptors/http-error.interceptor"
import { AuthGuard } from "@angular/fire/auth-guard"
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app"
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  provideFirestore,
} from "@angular/fire/firestore"
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics"
import { provideAnimations } from "@angular/platform-browser/animations"
import { provideImageKitLoader } from "@angular/common"

export const appConfig: ApplicationConfig = {
  providers: [

    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    // provideFirestore(() => getFirestore()),
    provideFirestore(() => initializeFirestore(
      getApp(),
      { localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }) }),
    ),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnimations(),
    // provideAnimationsAsync(),
    importProvidersFrom(
      // BrowserAnimationsModule,
      // BrowserModule,
      // HammerModule,
      AuthGuard,
      NgxMapboxGLModule.withConfig({ accessToken: environment.mapboxConfig.token }),
    ),
    // provideExperimentalZonelessChangeDetection(), // mapBox map does not render
    provideZoneChangeDetection({ eventCoalescing: true }),
    // provideClientHydration(withEventReplay()),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      appRoutes,
      // withEnabledBlockingInitialNavigation(), // for SSR
      // withViewTransitions(), // needs some kind of filter for pages only, makes rendering nested views very slow
    ),
    AuthTokenHttpInterceptorProvider,
    HttpErrorInterceptorProvider,
    provideImageKitLoader(environment.imagekit_urlEndpoint),
  ],
}
