import { Component, inject, input, signal } from "@angular/core"
import { LngLat, Story } from "../../content/content.model"
import { MapMapboxComponent } from "../../map/map-mapbox.component"
import { MapService } from "../../map/map.service"
import { FirestoreReadService } from "../../content/firestore-read.service"
import { faSearchMinus, faSearchPlus, faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { EditorService } from "../services/editor.service"
import { faLoader } from "@fortawesome/pro-regular-svg-icons"
import { ConfirmNewMapLocationDialogComponent } from "../../dialogs/confirm-new-map-location.dialog.component"
import { RouteService } from "../../../services/route.service"
import { UiIconComponent } from "ui/icon"

@Component({
  imports: [
    MapMapboxComponent,
    UiIconComponent,
    ConfirmNewMapLocationDialogComponent,
  ],
  standalone: true,
  template: `
    <div style="height: 400px; position: relative">
      <e2e-map-mapbox
        [showMarkers]="true"
        [story]="story()"
        [storyZoomLevel]="zoomLevel()"
        [markerLocation]="newMarkerLocation()"
        (mapClick)="changeMarkerLocation($event)"
        (zoomLevelChange)="zoomLevelChange($event)"
      />

      <div
        class="row absolute"
        style="bottom: 20px; right: 20px; padding: 6px 12px"
      >
        <button
          class="btn btn-ghost text-primary text-4xl"
          (click)="zoomOut()"
        >
          <lib-ui-icon [icon]="faSearchMinus"/>
        </button>
        <button
          class="btn btn-ghost text-primary text-4xl"
          (click)="zoomIn()"
        >
          <lib-ui-icon [icon]="faSearchPlus"/>
        </button>
      </div>

      @if (confirmingChange()) {
        <e2e-confirm-new-map-location-dialog 
          (confirmed)="confirmChange(newMarkerLocation())"
          (cancelled)="cancelChange()"
        />
      }

      @if (saving()) {
        <div
          class="column wide"
          style="position: absolute; top: 0; left: 0; height: 100%; justify-content: center; z-index: 3; backdrop-filter: blur(5px)"
        >
          <div class="row" style="justify-content: center; font-size: 180px">
            <lib-ui-icon [icon]="faLoader" animation="spin"/>
          </div>
        </div>
      }
    </div>
  `,
  selector: "e2e-editor-map",
})
export class EditorMapComponent {
  private localMapService = inject(MapService)
  private firestoreReadService = inject(FirestoreReadService)
  private editorService = inject(EditorService)
  private routeService = inject(RouteService)

  story = input.required<Story | undefined>()

  saving = this.editorService.saving
  preSaving = this.editorService.preSaving
  confirmingChange = signal(false)
  newMarkerLocation = signal<LngLat | undefined>(undefined)
  zoomLevel = signal<number | undefined>(undefined)

  changeMarkerLocation(markerLocation: LngLat) {
    if (!this.routeService.section().MODERATION) {
      this.confirmingChange.set(true)
      this.newMarkerLocation.set(markerLocation)
    }
  }

  cancelChange() {
    this.confirmingChange.set(false)
    this.newMarkerLocation.set(undefined)
  }

  confirmChange(markerLocation: LngLat | undefined) {
    const content = this.editorService.editorContent()
    if (content && markerLocation) {
      this.editorService.saveEditor({
        ...content,
        settings: {
          ...content.settings,
          location: {
            ...content.settings.location,
            lat: markerLocation.lat,
            lng: markerLocation.lng,
          },
        },
      })
      this.confirmingChange.set(false)
      this.newMarkerLocation.set(undefined)
    }
  }

  zoomLevelChange(zoomLevel: number) {
    this.zoomLevel.set(zoomLevel)
  }

  zoomOut() {
    const zoomLevel = this.zoomLevel()
    const currentZoomLevel = zoomLevel !== undefined ? zoomLevel : this.localMapService.zoomLevel()
    this.zoomLevel.set(currentZoomLevel - 1)
  }

  zoomIn() {
    const zoomLevel = this.zoomLevel()
    const currentZoomLevel = zoomLevel !== undefined ? zoomLevel : this.localMapService.zoomLevel()
    this.zoomLevel.set(currentZoomLevel + 1)
  }

  protected readonly faSearchMinus = faSearchMinus
  protected readonly faSearchPlus = faSearchPlus
  protected readonly faSpinner = faSpinner
  protected readonly faLoader = faLoader
  protected readonly confirm = confirm
}
