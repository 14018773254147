import { Component, computed, inject, input, signal } from "@angular/core"
import { UiInheritDirective } from "ui/inherit"
import { UserLoginService } from "./user-login.service"
import { ProviderType } from "util/auth-provider"
import { FirebaseAuthService } from "../data-access/firebase-auth.service"
import { FirebaseAuthProfileService } from "../data-access/firebase-auth-profile.service"
import { UserSignOutComponent } from "./user-sign-out.component"
import { UserSignInComponent } from "./user-sign-in.component"

@Component({
  imports: [
    UiInheritDirective,
    UserSignOutComponent,
    UserSignInComponent,
  ],
  standalone: true,
  template: `
    @if (!userInit()) {
      <p>Loading...</p>
    }

    @if (userInit()) {
      <div class="column-center">
        <div style="font-size: 36px; text-align: center">
          Welcome
        </div>
        @if (loggedIn()) {
          <e2e-sign-out
            [afUserEmail]="afUserEmail()"
            [profile]="profile()"
            [providerId]="providerId()"
            (signOut)="signOut()"
          />
        }
        @if (loggedOut()) {
          <e2e-sign-in
            [signInWithPassword]="signInWithPassword()"
            inherit
          />
        }

        <ng-content select="content"/>
        
      </div>
    }
  `,
  selector: 'e2e-user-login',
})
export class UserLoginComponent {
  private firebaseAuthService = inject(FirebaseAuthService)
  private firebaseAuthProfileService = inject(FirebaseAuthProfileService)
  private featureSharedLoginService = inject(UserLoginService)

  providerTypes = input<ProviderType[]>([
    // ProviderType.APPLE,
    // ProviderType.FACEBOOK,
    ProviderType.GOOGLE,
    ProviderType.GITHUB,
    ProviderType.MICROSOFT,
    // ProviderType.TWITTER
    ProviderType.PASSWORD,
  ])

  signInWithPassword = this.featureSharedLoginService.withPassword_flag
  userInit = this.firebaseAuthService.userInit
  afUserEmail = this.firebaseAuthService.afUserEmail
  loggedIn = this.firebaseAuthService.loggedIn
  loggedOut = computed(() => !this.firebaseAuthService.loggedIn())
  providerId = this.firebaseAuthService.providerId
  profile = this.firebaseAuthProfileService.profile

  /**
   * connect to email verification or some other event to indicate first time member
   */
  newAccount = signal(false)

  signOut() {
    this.featureSharedLoginService.signOut()
  }

}
