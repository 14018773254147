import { Component, computed, inject, input, signal } from "@angular/core"
import { ImageView } from "../fields.type"
import { TimelineSvgGraphicsService } from "../../../pages/timeline/timeline.svg-graphics.service"
import { StorageService } from "../../../../services/storage.service"
import { UiThemeService } from "ui/theme"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    @if (imageView()) {
      <img
        [src]="fileUrl()"
        [alt]="imageView().alt || 'image'"
        style="position: relative; object-fit: contain"
      />
    }
  `,
  selector: "e2e-image-view",
})
export class ImageViewComponent {
  private timelineSvgGraphicsService = inject(TimelineSvgGraphicsService)
  private storageService = inject(StorageService)
  private uiThemeService = inject(UiThemeService)

  imageView = input.required<ImageView>()

  width = computed(() => (this.imageView().contentWidth >= 0 ? this.imageView().contentWidth : undefined))
  height = signal(undefined)
  fileUrl = computed(
    () =>
      // this.domSanitizer.bypassSecurityTrustResourceUrl(
      // this.svgGraphicsService.svgGraphic(this.imageView().svgGraphics) &&
      this.storageService.getFileUrl(
        this.svgGraphicsFileUrl() || this.imageView().filePath,
      )
      // ),
  )

  svgGraphicsFileUrl() {
    const filePath = this.timelineSvgGraphicsService.svgGraphic(this.imageView().svgGraphics) || ""
    if (this.uiThemeService.selectedTheme().name === "redline_dark") {
      return filePath
        .replace(".svg", "-alt.svg")
    }
    return filePath
  }
}
