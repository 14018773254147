import { inject, Injectable } from "@angular/core"
import { UtilsService } from "@shared"
import { environment } from "../../environments/environment"

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private utils = inject(UtilsService)

  get newImageFilePath(): string {
    return "/images/" + this.utils.createId()
  }

  get newVideoFilePath(): string {
    return "/videos/" + this.utils.createId()
  }

  getFileUrl(filePath: string | undefined): string {
    // if (filePath) {
    //   return environment.imagekit_urlEndpoint + filePath
    // }
    const storageBucket = environment.firebaseConfig.storageBucket
    if (storageBucket && filePath) {
      return "https://storage.googleapis.com/" + storageBucket + filePath
    }
    return ""
  }

}
