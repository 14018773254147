import { Component, computed, effect, inject, signal, untracked } from "@angular/core"
import { Classification, Content, ContentType, LngLat } from "../content/content.model"
import { RegionProperties, StoryProperties } from "../map/map.type"
import { MapMapboxComponent } from "../map/map-mapbox.component"
import { RouterLink } from "@angular/router"
import { MapService } from "../map/map.service"
import { HolcAttributionDialogComponent } from "../dialogs/holc-attribution.dialog.component"
import { debounceSignal } from "@ddtmm/angular-signal-generators"
import { PageToolbarService } from "../page-toolbar.service"
import { RedlineMapDetailsComponent } from "./redline-map-details.component"
import { UiInheritDirective } from "ui/inherit"
import { EditorLoadService } from "../editor/services/editor-load.service"
import { EditorService } from "../editor/services/editor.service"
import { EditorComponent } from "../editor/editor.component"
import { ProfileProgress } from "../profile/profile.model"
import { ProfileService, Role } from "../profile/profile.service"
import { RouteService } from "../../services/route.service"
import { FirestoreReadService } from "../content/firestore-read.service"
import { RegionEnum } from "../../regions/region.model"
import { HelpTipsComponent } from "feature/redline/help-tips"
import { RegionService } from "../../regions/region.service"
import { NextSectionButtonComponent } from "../shared/next-section-button.component"

@Component({
  imports: [
    MapMapboxComponent,
    RouterLink,
    UiInheritDirective,
    HolcAttributionDialogComponent,
    RedlineMapDetailsComponent,
    EditorComponent,
    HelpTipsComponent,
    NextSectionButtonComponent,
  ],
  standalone: true,
  template: `
    @if (!visited()) {
      <lib-feature-help-tips (close)="visitConfirmed()"/>
    }

    @if (showNewContentEditor() && newContent(); as newContent) {
      <e2e-editor
        context="contributor"
        [content]="newContent"
        [isNewContent]="true"
        (close)="showNewContentEditor.set(false)"
        inherit
      />
    }

    <div
      class="column"
      style="height: 100vh"
    >
      <!--      <e2e-page-toolbar/>-->
      <div
        #pageToolbarPlaceholder
        style="width: 100%"
        [style.height.px]="pageToolbarHeight()"
      ></div>
      <div class="column" style="align-items: center">
        <div style="font-size: 22px; font-weight: 600">
          Redlining in
          {{ brandHeader()?.title }}
        </div>
      </div>

      <div class="row" style="flex-grow: 1; align-items: normal">
        <div style="flex-grow: 1">
          <e2e-map-mapbox
            [scrollZoom]="true"
            (mapClick)="mapClick($event)"
            (regionClick)="regionClick($event)"
            inherit
          />
        </div>
      </div>
      <button routerLink="/timeline" style="width: 100%; background: #f3f3f3">
        <e2e-next-section-button/>
      </button>
    </div>

    @if (showAttributionModal()) {
      <e2e-holc-attribution-dialog (confirmed)="showAttributionModal.set(false)"/>
    }

    <e2e-redline-map-details
      [showMapModal]="showMapModal()"
      [showContent]="showContent()"
      (hideMapModal)="showMapModal.set(false)"
      (showAttributionModal)="showAttributionModal.set(true)"
      (createNewContent)="createNewContent(markerLocation())"
    />

    <!--
          <ng-template pTemplate="header">
            <lib-ui-icon [icon]="faArrowsAlt"/>
            <div class="center wide">
              Learn - Discover - Share
            </div>
          </ng-template>
          <ng-template pTemplate="footer"/>
    -->
  `,
  selector: "e2e-redline-map",
})
export class RedlineMapComponent {
  private redlineMapService = inject(MapService)
  // private breakpointObserver = inject(BreakpointObserver)
  private pageToolbarService = inject(PageToolbarService)
  private editorService = inject(EditorService)
  private editorLoadService = inject(EditorLoadService)
  private profileService = inject(ProfileService)
  private routeService = inject(RouteService)
  private firestoreReadService = inject(FirestoreReadService)
  private regionService = inject(RegionService)

  brandHeader = this.regionService.brandHeader

  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight
  editorContentId = computed(() => this.editorService.editorContent()?.id)

  newContent = signal<Content | undefined>(undefined)
  // handsetBreakpointObserver = toSignal(this.breakpointObserver.observe([Breakpoints.Handset]))
  // handset = computed(() => this.handsetBreakpointObserver()?.matches)
  markerLocation = signal<LngLat | undefined>(undefined)
  showContentDebounced = debounceSignal(0, 1000)
  showAttributionModal = signal(false)
  showContent = signal(false)
  showMapModal = signal(false)

  showNewContentEditor = signal(false)
  visited = signal(false)

  constructor() {
    effect(() => this.pageToolbarHeight()) // required in order to trigger template refresh when pageToolbarHeight changes
    this.visited.set(!!localStorage.getItem("visitedRedlineMap"))
    effect(() => {
      if (this.markerLocation()) {
        untracked(() => {
          this.showContentDebounced.set(Date.now())
          this.showContent.set(false)
        })
      }
    })
    effect(() => {
      if (this.showContentDebounced()) {
        untracked(() => this.showContent.set(true))
      }
    })
  }

  visitConfirmed() {
    this.visited.set(true)
    localStorage.setItem("visitedRedlineMap", "true")
  }

  // mapRegionProperties = signal<RegionProperties | null>(null)

  reset() {
    this.redlineMapService.setMarkerLocation(undefined)
    this.redlineMapService.setStoryProperties({ authorUserName: "", storyId: "", text: "", filePath: "" })
    // this.mapRegionProperties.set(null)
  }

  setMarkerLocation(location: LngLat) {
    this.markerLocation.set(location)
  }

  mapClick(markerLocation: LngLat) {
    // console.log("mapClick")
    this.redlineMapService.unsetRegionProperties()
    this.redlineMapService.unsetStoryProperties()
    this.showMapModal.set(true)
    // this.reset()
    this.setMarkerLocation(markerLocation)
    /*
        if (this.storyMode()) {
          this.localMapsService.setMarkerLocation(markerLocation)
        }
    */
  }

  contentMarkerClick(storyProperties: StoryProperties) {
    // console.log("contentMarkerClick")
    // this.reset()
    this.redlineMapService.setStoryProperties(storyProperties)
  }

  regionClick(event: { lngLat: LngLat; properties: RegionProperties | undefined }) {
    // console.log("regionClick")
    // console.log(event.properties)
    if (event.properties) {
      // ?.clarifying_remarks) {
      this.redlineMapService.setRegionProperties(event.properties)
    }
    // this.reset()
    // this.mapRegionProperties.set(event.properties)
  }

  /*
    switchToDiscoverMode() {
      this.modeSwitch(LocalMapsMode.DISCOVER)
    }

    switchToLearnMode() {
      this.modeSwitch(LocalMapsMode.LEARN)
    }

    switchToStoryMode() {
      this.modeSwitch(LocalMapsMode.STORY)
    }

    modeSwitch(localMapsMode: LocalMapsMode) {
      this.reset()
      this.localMapsService.setregionProperties(null)
      this.routeService.changeFragment(localMapsMode.toLowerCase())
    }
  */

  createNewContent(markerLocation: LngLat | undefined) {
    if (markerLocation) {
      const user = {
        id: this.profileService.userId(),
        name: this.profileService.userName(),
        role: this.profileService.userRole(),
        region: this.profileService.region(),
      }

      if (!(user.id && user.name && user.role)) {
        this.routeService.changeRoute([], { p: "my-account-login", s: "" })
        return
      }

      if (this.profileService.profileStatus().progress === ProfileProgress.INCOMPLETE) {
        this.routeService.changeRoute([], { p: "my-account-login", s: "" })
        // this.routeService.changeRoute([], { p: "my-account-profile", s: "" })
        return
      }

      const content = this.buildNewContent(markerLocation, user)
      if (content) {
        this.newContent.set(content)
        this.showNewContentEditor.set(true)
      }
    }
  }

  buildNewContent(location: LngLat, user: { id: string, name: string, role: Role, region: RegionEnum }) {
    const newContent = this.firestoreReadService
      .newContent(
        user,
        [ContentType.CONTRIBUTED, ContentType.LOCAL_MAP],
        Classification.CONTRIBUTED
      )
    return <Content>{
      ...newContent,
      settings: {
        ...newContent.settings,
        location: {
          lat: location.lat,
          lng: location.lng,
        },
      },
    }
  }

}

/**
 *
 *
 *   styles: `
 *     .number {
 *       border: 1px solid white;
 *       border-radius: 2em;
 *       width: 2em;
 *       height: 2em;
 *       line-height: 2em;
 *       text-align: center;
 *       font-size: 1.2em;
 *       color: white;
 *       box-sizing: border-box;
 *       flex: 0 0 2em;
 *     }
 *
 *     .header {
 *       font-size: 1.2em;
 *       line-height: 2em;
 *       font-weight: bold;
 *       color: white;
 *       flex: 1;
 *     }
 *   `,
 *
 *
 *         <!--
 *                 <b>
 *                   {{ region.location || region.name }}
 *                   {{ region.security_grade }}
 *                   {{ region.area_number }}
 *                   {{ region.date }}
 *                 </b>
 *                 <p>
 *                   <i>
 *                     Class and Occupation
 *                   </i>
 *                   <br/>
 *                   {{ region.occupation_or_type }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Foreign Families (Nationality)
 *                   </i>
 *                   <br/>
 *                   {{ region.foreign_born_percent }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Negro Infiltration
 *                   </i>
 *                   <br/>
 *                   {{ region.negro_percent || '-' }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Shifting or Infiltration
 *                   </i>
 *                   <br/>
 *                   {{ region.infiltration_of || '-' }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Description
 *                   </i>
 *                   <br/>
 *                   {{ region.clarifying_remarks }}
 *                 </p>
 *         -->
 *
 *
 *
 *             <!--
 *             <div
 *               class="column red-paper-texture"
 *               style="padding: 10px; width: 320px"
 *             >
 *
 *               &lt;!&ndash;
 *                         @if (learnMode() || !handset() || true) {
 *                           <section
 *                             class="column"
 *                             [style.flex]="learnMode() ? 1 : 0"
 *                           >
 *                             <div
 *                               class="row"
 *                               (click)="switchToLearnMode()"
 *                             >
 *                               <div class="number">
 *                                 1
 *                               </div>
 *                               <div class="header">
 *                                 Learn about Redlining
 *                               </div>
 *                             </div>
 *                             @if (learnMode()) {
 *                               <e2e-local-map-learn/>
 *                             }
 *                           </section>
 *                         }
 *               &ndash;&gt;
 *
 *               &lt;!&ndash;
 *                         @if (storyMode() || !handset() || true) {
 *                           <section
 *                             class="column"
 *                             [style.flex]="storyMode() ? 1 : 0"
 *                           >
 *                             <div
 *                               class="row"
 *                               (click)="switchToStoryMode()"
 *                             >
 *                               <div class="number">
 *                                 2
 *                               </div>
 *                               <div class="header">
 *                                 Tell Your Story
 *                               </div>
 *                             </div>
 *                             @if (storyMode()) {
 *                               <e2e-local-map-story/>
 *                             }
 *                           </section>
 *                         }
 *               &ndash;&gt;
 *
 *               &lt;!&ndash;
 *                         @if (discoverMode() || !handset() || true) {
 *                           <section
 *                             class="column"
 *                             [style.flex]="discoverMode() ? 1 : 0"
 *                           >
 *                             <div
 *                               class="row"
 *                               (click)="switchToDiscoverMode()"
 *                             >
 *                               <div class="number">
 *                                 3
 *                               </div>
 *                               <div class="header">
 *                                 Discover Stories
 *                               </div>
 *                             </div>
 *                             @if (discoverMode()) {
 *                               <e2e-local-map-discover/>
 *                             }
 *                           </section>
 *                         }
 *               &ndash;&gt;
 *
 *               <button routerLink="/timeline" style="margin-top: auto">
 *                 <e2e-next-section-button/>
 *               </button>
 *
 *             </div>
 *     -->
 */
