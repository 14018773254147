import { Component, computed, inject, input, signal } from "@angular/core"
import { Story } from "../content/content.model"
import { SectionEnum } from "../shared/fields/fields.type"
import { VideoViewComponent } from "../shared/fields/video/video-view.component"
import { StorageService } from "../../services/storage.service"

@Component({
  imports: [
    VideoViewComponent,
  ],
  standalone: true,
  template: `
    <div
      class="column outline outline-2 p-3 cursor-pointer"
      (click)="toggleView()"
    >
      <div class="row-nowrap">
        @if (imageView().src) {
          <div class="w-20 aspect-square">
            <img
              alt="image"
              [src]="imageView().src"
            />
          </div>
        }

        <div class="column">
          <div class="column gap-0">
            @if (title().value) {
              <div
                class="font-bold"
                [class]="showFullText() ? '' : 'line-clamp-2'"
              >
                {{ title().value }}
              </div>
            }
            @if (subTitle().value) {
              <div
                [class]="showFullText() ? '' : 'line-clamp-2'"
              >
                {{ subTitle().value }}
              </div>
            }
          </div>
        </div>
      </div>

      @if (videoView().src) {
        <e2e-video-view [videoUrl]="videoView().src"/>
      }

      @if (body().value) {
        <div
          [class]="showFullText() ? '' : 'line-clamp-1'"
        >
          {{ body().value }}
        </div>
      }

      @if (showFullText()) {
        <div class="italic">
          from
          <span class="text-primary">
            {{ story().created.userName }}
          </span>
        </div>
      }

    </div>

  `,
  selector: "e2e-story-linked-story",
})
export class StoryLinkedStoryComponent {
  private storageService = inject(StorageService)

  story = input.required<Story>()
  imageView = computed(() => ({
    alt: this.story()[SectionEnum.IMAGE].alt,
    src: this.storageService.getFileUrl(this.story()[SectionEnum.IMAGE].filePath),
  }))
  videoView = computed(() => {
    const video = this.story()?.[SectionEnum.VIDEO]
    return {
      src: (video?.filePath && this.storageService.getFileUrl(video.filePath)) || "",
    }
  })
  showFullText = signal(false)

  body = computed(() => this.story()[SectionEnum.BODY])
  title = computed(() => this.story()[SectionEnum.TITLE])
  subTitle = computed(() => this.story()[SectionEnum.SUBTITLE])


  toggleView() {
    this.showFullText.update(show => !show)
  }

}
