import { inject, Injectable } from "@angular/core"
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Observable, throwError } from "rxjs"
import { FirebaseAuthService } from "../data-access/firebase-auth.service"

@Injectable({ providedIn: "root" })
export class AuthTokenHttpInterceptor implements HttpInterceptor {
  private firebaseAuthService = inject(FirebaseAuthService)

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clone = req.clone()
    const token = this.firebaseAuthService.idTokenResultWithClaims()?.token
    if (token) {
      clone = clone.clone({ headers: req.headers.set("Authorization", "Bearer " + token) })
      return next.handle(clone)
    }
    return throwError(() => {
      console.log(req)
      return "AuthTokenHttpInterceptor is has undefined idTokenResult()?.token"
    })

  }
}

export const AuthTokenHttpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenHttpInterceptor,
  multi: true
}
