import { computed, inject, Injectable, WritableSignal } from "@angular/core"
import { Profile, ProfileField, ProfileProgress, ProfileStatus } from "./profile.model"
import { RouteService } from "../../services/route.service"
import { enumStringKeys } from "util/enum-keys"
import { FirebaseAuthService } from "../../data-access/firebase-auth.service"
import { FirebaseAuthProfileService } from "../../data-access/firebase-auth-profile.service"

export enum Role {
  ADMIN = "ADMIN",
  BLOCKED = "BLOCKED",
  USER_ADMIN = "USER_ADMIN",
  CONTRIBUTOR = "CONTRIBUTOR",
  EDITOR = "EDITOR",
  MODERATOR = "MODERATOR",
}

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private routeService = inject(RouteService)
  private firebaseAuthService = inject(FirebaseAuthService)
  private firebaseAuthProfileService = inject(FirebaseAuthProfileService)

  /**
   * providing userService signals so that components
   * won't have to inject both profile and user services
   */
  idTokenResultWithClaims = this.firebaseAuthService.idTokenResultWithClaims
  afUser = this.firebaseAuthService.afUser
  userInit = this.firebaseAuthService.userInit
  userId = this.firebaseAuthService.userId
  loggedIn = this.firebaseAuthService.loggedIn
  region = this.routeService.region
  claimsRoles = computed<Role[]>(() => this.firebaseAuthService.claimsRoles() as Role[])

  profile = this.firebaseAuthProfileService.profile as WritableSignal<Profile | undefined>

  userName = computed(() =>
    this.firebaseAuthProfileService.profile()?.userName
    || this.firebaseAuthService.providerData().displayName
    || this.firebaseAuthService.afUserDisplayName()
    || this.firebaseAuthService.providerData().email
    || "",
  )
  // returns the first role that is found in claims
  userRole = computed(() => {
    const roles = [Role.BLOCKED, Role.ADMIN, Role.EDITOR, Role.MODERATOR, Role.USER_ADMIN]
    const claims = this.claimsRoles()
    return roles.find(role => claims?.includes(role)) || Role.CONTRIBUTOR
  })
  profileStatus = computed(() => this.getProfileStatus(this.profile()))

  hasRole = computed(() => this.mapRoles(
    this.idTokenResultWithClaims()?.claims?.["roles"] as Role[] || [],
    this.profileStatus(),
  ))
  hasRoles = computed(() => {
    const hasRoles = [] as Role[]
    for (const [role, value] of Object.entries(this.hasRole())) {
      if (value) hasRoles.push(role as Role)
    }
    return hasRoles
  })

  isModerator = computed(() => this.hasRole().MODERATOR)
  isEditor = computed(() => this.hasRole().EDITOR)
  isAdmin = computed(() => this.hasRole().ADMIN)
  isUserAdmin = computed(() => this.hasRole().USER_ADMIN)
  isBlocked = computed(() => this.hasRole().BLOCKED)
  isContributor = computed(() => this.hasRole().CONTRIBUTOR)

  homeLocation = computed(() => this.profile()?.homeLocation || "")
  birthYear = computed(() => this.profile()?.birthYear || 0)
  title = computed(() => this.profile()?.title || "")
  pronouns = computed(() => this.profile()?.pronouns || [])
  shortBio = computed(() => this.profile()?.shortBio || "")
  issues = computed(() => this.profile()?.issues || [])
  races = computed(() => this.profile()?.races || [])
  genders = computed(() => this.profile()?.genders || [])
  sleepLocations = computed(() => this.profile()?.sleepLocations || [])
  educations = computed(() => this.profile()?.educations || [])
  householdIncomeRanges = computed(() => this.profile()?.householdIncomeRanges || [])
  profileImage = computed(() => this.profile()?.profileImage)

  // saveToProfileField(fieldName: ProfileField, value: unknown) {
  //   this.userLoggedInService.saveToProfileField(fieldName, value)
  // }

  // saveToProfile(profile: Profile | undefined) {
  //   if (profile) {
  //     this.profileEditorService.saveToProfile(profile)
  //   }
  // }

  getProfileStatus(profile: Profile | undefined): ProfileStatus {
    let profileProgress = ProfileProgress.INCOMPLETE
    let completedCount = 0
    let requiredCount = 0

    if (profile) {
      enumStringKeys(ProfileField).map(fieldName => {
        const value = profile[fieldName]
        const currentYear = new Date(Date.now()).getFullYear()
        switch (fieldName) {
          case "birthYear": {
            const birthYear = value as number | undefined
            if (birthYear && birthYear > 1900 && birthYear < currentYear) {
              completedCount++
              requiredCount++
            }
          }
            break
          case "homeLocation":
          case "title":
          case "userName":
            if (value) {
              completedCount++
              requiredCount++
            }
            break
          case "shortBio": {
            const shortBio = value as string | undefined
            if (shortBio) {
              completedCount++
            }
          }
            break
          case "issues":
          case "pronouns":
          case "educations":
          case "genders":
          case "householdIncomeRanges":
          case "races":
          case "sleepLocations": {
            const values = value as string[] | undefined
            if (values?.find(item => item.length)) {
              completedCount++
            }
          }
            break
          case "profileImage":
            break
        }
      })
      // console.log("requiredCount, need exactly 4 to set REQUIRED", requiredCount)
      if (requiredCount === 4) {
        profileProgress = ProfileProgress.REQUIRED
      }
      // console.log("add 1 to completedCount if true", profile.profileImage?.filePath)
      if (profile.profileImage?.filePath) {
        completedCount++
      }
      // console.log("completedCount, need exactly 13 to set COMPLETE", completedCount)
      if (completedCount === 13) {
        profileProgress = ProfileProgress.COMPLETE
      }
    }
    const completedPercent = Math.floor(completedCount / 13 * 10) * 10

    return {
      percent: completedPercent,
      progress: profileProgress,
      type: "circle",
    }
  }

  mapRoles(customClaimsRoles: Role[], profileStatus: ProfileStatus): { [K in Role]: boolean } {
    const allRoles: Role[] = enumStringKeys(Role)
    const hasRole = {} as { [K in Role]: boolean }
    /**
     * set true for all roles in custom claims
     * set false for all the rest in allRoles
     */
    allRoles.map(role => {
      hasRole[role] = !!customClaimsRoles.find(customClaimsRole => customClaimsRole === role)
    })
    /**
     * add roles if has parent role
     * for example, Admin gets all roles
     */
    allRoles.map(role => {
      switch (role) {
        case Role.ADMIN:
          if (hasRole[role]) {
            hasRole[Role.USER_ADMIN] = true
            hasRole[Role.EDITOR] = true
            hasRole[Role.MODERATOR] = true
            hasRole[Role.CONTRIBUTOR] = true
          }
          break
        case Role.EDITOR:
          if (hasRole[role]) {
            hasRole[Role.MODERATOR] = true
            hasRole[Role.CONTRIBUTOR] = true
          }
          break
        case Role.MODERATOR:
          if (hasRole[role]) {
            hasRole[Role.CONTRIBUTOR] = true
          }
          break
        case Role.CONTRIBUTOR:
          switch (profileStatus.progress) {
            case ProfileProgress.INCOMPLETE:
              // case ProfileProgress.EXCEPTION:
              break
            case ProfileProgress.REQUIRED:
            case ProfileProgress.COMPLETE:
              hasRole[Role.CONTRIBUTOR] = true
              break
          }
      }
    })
    /**
     * Remove all roles if has Blocked role
     */
    allRoles.map(role => {
      switch (role) {
        case Role.BLOCKED:
          if (hasRole[role]) {
            hasRole[Role.USER_ADMIN] = false
            hasRole[Role.ADMIN] = false
            hasRole[Role.EDITOR] = false
            hasRole[Role.MODERATOR] = false
            hasRole[Role.CONTRIBUTOR] = false
          }
          break
      }
    })
    return hasRole
  }

}
