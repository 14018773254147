import { Component, computed, inject, input, output, signal } from "@angular/core"
import {
  AnyLayer,
  EventData,
  FillPaint,
  GeoJSONSourceOptions,
  Map,
  MapboxEvent,
  MapLayerMouseEvent,
  MapMouseEvent,
  SymbolLayout,
  SymbolPaint,
} from "mapbox-gl"
import { RegionProperties, StoryProperties } from "./map.type"
import { LngLat, Story } from "../content/content.model"
import { NgxMapboxGLModule } from "ngx-mapbox-gl"
import { MapService } from "./map.service"
import { regionData } from "../../regions/region.model"
import { RouteService } from "../../services/route.service"
import { FeatureCollection, Geometry } from "geojson"
import { GeoJSONSourceComponent } from "ngx-mapbox-gl/lib/source/geojson/geojson-source.component"
import { UtilAnalyticsService } from "util/analytics"
import { StorageService } from "../../services/storage.service"

type GeoJsonRegion = {
  sourceId: GeoJSONSourceComponent["id"]
  sourceDataUrl: GeoJSONSourceOptions["data"]
  layerId: AnyLayer["id"]
}

@Component({
  imports: [
    NgxMapboxGLModule,
  ],
  standalone: true,
  template: `
    <mgl-map
      [style]="mapStyle"
      [zoom]="[zoomLevel()]"
      [bearing]="[0]"
      [pitch]="[45]"
      [center]="center()"
      [scrollZoom]="scrollZoom()"
      [style.height.%]="100"
      (mapLoad)="loadMap($event)"
      (zoomEnd)="zoomEvent($event)"
      (mapClick)="onMapClick($event)"
    >
      <mgl-image id="active-marker" [url]="activeMarker()"/>
      <mgl-image id="contributed-marker" [url]="contributedMarker()"/>
      <mgl-image id="featured-marker" [url]="featuredMarker()"/>
      <mgl-image id="regional-marker" [url]="regionalMarker()"/>

      @for (geoJsonRegion of geoJsonRegions(); track geoJsonRegion.sourceId) {
        <mgl-geojson-source
          [id]="geoJsonRegion.sourceId"
          [data]="geoJsonRegion.sourceDataUrl"
        />
        <mgl-layer
          [id]="geoJsonRegion.layerId"
          type="fill"
          [source]="geoJsonRegion.sourceId"
          [paint]="fillPaint"
          (layerClick)="onRegionClick($event)"
        />
      }

      <!--
            @if (!regionPopup()?.properties?.clarifying_remarks && regionPopup(); as popup) {
              <mgl-popup [lngLat]="popup.lngLat">
                &lt;!&ndash;          {{ popup.properties.location || popup.properties.name }}&ndash;&gt;
                {{ popup.properties.security_grade }}
                {{ popup.properties.area_number }}
                &lt;!&ndash;          {{ popup.properties.date }}&ndash;&gt;
                &lt;!&ndash;          {{ popup.properties.clarifying_remarks }}&ndash;&gt;
                @if (isAdmin()) {
                  <pre>
                    {{ popup.properties | json }}
                  </pre>
                }
              </mgl-popup>
            }
      -->

      <!-- custom markers -->
      @if (showMarkers() && markerLocation(); as location) {

        <mgl-marker [lngLat]="location" anchor="bottom">
          <div style="width: 30px">
            <img [src]="activeMarker()" alt=""/>
          </div>
        </mgl-marker>
      }

      <!-- content markers and clusters -->
      @if (showMarkers() && story()) {
        <!-- center and selected point -->
        <mgl-geojson-source
          id="story-markers-source"
          [data]="storyMarkerCollection()"
        />
        <mgl-layer
          id="story-markers"
          type="symbol"
          source="story-markers-source"
          [layout]="markerLayoutActive"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />
      }
      @if (showMarkers() && !story()) {
        <mgl-geojson-source
          id="my-markers-source"
          [data]="myStoriesMarkerCollection()"
        />
        <mgl-layer
          id="my-markers"
          type="symbol"
          source="my-markers-source"
          [layout]="markerLayoutContributed"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />

        <mgl-geojson-source
          id="contributed-markers-source"
          [data]="contributedStoriesMarkerCollection()"
        />
        <mgl-layer
          id="contributed-markers"
          type="symbol"
          source="contributed-markers-source"
          [layout]="markerLayoutContributed"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />
        <mgl-geojson-source
          id="featured-markers-source"
          [data]="featuredStoriesMarkerCollection()"
        />
        <mgl-layer
          id="featured-markers"
          type="symbol"
          source="featured-markers-source"
          [layout]="markerLayoutFeatured"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />
        <mgl-geojson-source
          id="regional-markers-source"
          [data]="regionalStoriesMarkerCollection()"
        />
        <mgl-layer
          id="regional-markers"
          type="symbol"
          source="regional-markers-source"
          [layout]="markerLayoutRegional"
          [paint]="markerPaint"
          [minzoom]="0"
          [maxzoom]="24"
          (layerClick)="onContentMarkerClick($event)"
        />

      }
      <!-- <mgl-layer
      id="clusters"
      type="circle"
      [filter]="['has', 'point_count']"
      [source]="markerSource"
      [paint]="clusterPaint"
      />
    <mgl-layer
      id="clusterLabels"
      type="symbol"
      [filter]="['has', 'point_count']"
      [source]="markerSource"
      [layout]="clusterLabelLayout"
      [paint]="clusterLabelPaint"
      /> -->
      <!-- <mgl-popup
      *ngIf="regionProperties"
      [lngLat]="regionProperties.lngLat"
      >
      <h3>
        {{ regionProperties.properties.text }}
      </h3>
    </mgl-popup> -->
    </mgl-map>
  `,
  selector: "e2e-map-mapbox",
})
export class MapMapboxComponent {
  private localMapService = inject(MapService)
  private storageService = inject(StorageService)
  private routeService = inject(RouteService)
  private analyticsService = inject(UtilAnalyticsService)

  showMarkers = input(false)
  markerLocation = input<LngLat | undefined>(undefined)
  story = input<Story | undefined>(undefined) // from editor-map component
  storyZoomLevel = input<number | undefined>(undefined)
  scrollZoom = input(false)

  zoomLevelChange = output<number>()
  mapClick = output<LngLat>()
  contentMarkerClick = output<StoryProperties>()
  regionClick = output<{ lngLat: LngLat; properties: RegionProperties | undefined }>()

  activeMarker = signal(this.storageService.getFileUrl("/assets/images/map-marker-active.png"))
  contributedMarker = signal(this.storageService.getFileUrl("/assets/images/map-marker-contributed.png"))
  featuredMarker = signal(this.storageService.getFileUrl("/assets/images/map-marker-featured.png"))
  regionalMarker = signal(this.storageService.getFileUrl("/assets/images/map-marker-regional.png"))

  debugDialogVisible = signal(false)
  dialogVisible = signal(false)
  zoomLevel = computed(() => this.storyZoomLevel() || this.localMapService.zoomLevel())
  map: Map | undefined = undefined

  geoJsonRegions = signal<GeoJsonRegion[]>(
    Object.values(regionData)
      .filter(regionData => regionData.enabled)
      .map((regionData) => ({
        sourceId: regionData.region + "-sourceId",
        sourceDataUrl: this.storageService.getFileUrl("/assets/geojson/" + regionData.geoJson),
        layerId: regionData.region + "-layerId",
      }))
      .filter((geoJsonRegions) => {
        if (this.routeService.debug()) console.log(geoJsonRegions)
        return true
      }),
  ).asReadonly()

  center = computed(() =>
    this.story()?.settings.location as LngLat || this.localMapService.center()
  )

  mapStyle = "mapbox://styles/bradencrooks/clgmf26xr000v01qnfx5gbtwt"

  fillPaint: FillPaint = {
    "fill-color": [
      "case",
      ["==", ["get", "grade"], "A"],
      ["rgba", 121, 255, 97, 0.3],
      ["==", ["get", "grade"], "B"],
      ["rgba", 97, 194, 255, 0.3],
      ["==", ["get", "grade"], "C"],
      ["rgba", 255, 239, 97, 0.3],
      ["rgba", 255, 97, 97, 0.3],
    ],
    "fill-outline-color": [
      "case",
      ["==", ["get", "grade"], "A"],
      ["rgb", 121, 255, 97],
      ["==", ["get", "grade"], "B"],
      ["rgb", 97, 194, 255],
      ["==", ["get", "grade"], "C"],
      ["rgb", 255, 239, 97],
      ["rgb", 255, 97, 97],
    ],
  }
  markerLayoutActive: SymbolLayout = {
    "icon-image": "active-marker",
    "icon-size": .1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutContributed: SymbolLayout = {
    "icon-image": "contributed-marker",
    "icon-size": .1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutFeatured: SymbolLayout = {
    "icon-image": "featured-marker",
    "icon-size": .1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerLayoutRegional: SymbolLayout = {
    "icon-image": "regional-marker",
    "icon-size": .1,
    "icon-anchor": "bottom",
    // "text-field": ["get", "text"],
    // "text-anchor": "top",
  }
  markerPaint: SymbolPaint = {
    // "icon-color": "green",
    // "icon-halo-color": "green",
    // "text-halo-width": 1,
    // "text-halo-blur": 3,
    // "text-halo-color": "white",
  }
  /*
    clusterPaint: mapboxgl.CirclePaint = {
      "circle-color": "#FAA",
      "circle-radius": 24,
    }
    clusterLabelLayout: mapboxgl.SymbolLayout = {
      "text-field": ["get", "point_count"],
    }
    clusterLabelPaint: mapboxgl.SymbolPaint = {
      "text-color": "#000",
    }
  */

  pinMarkerPoint = signal<Geometry | null>(null)
  // regionProperties: { lngLat: mapboxgl.LngLatLike, properties: StoryProperties } | null = null
  regionProperties = this.localMapService.regionProperties
  storyMarkerCollection = computed<FeatureCollection>(() => {
    const story_input = this.story()
    const storyMarkerFeature = this.localMapService.getFeatureFromStory(story_input)
    return (
      (story_input &&
        storyMarkerFeature && {
          type: "FeatureCollection",
          features: [storyMarkerFeature],
        }) || {
        type: "FeatureCollection",
        features: [],
      }
    )
  })
  myStoriesMarkerCollection = this.localMapService.myStoriesMarkerCollection
  publishedStoriesMarkerCollection = this.localMapService.publishedStoriesMarkerCollection
  contributedStoriesMarkerCollection = this.localMapService.contributedStoriesMarkerCollection
  featuredStoriesMarkerCollection = this.localMapService.featuredStoriesMarkerCollection
  regionalStoriesMarkerCollection = this.localMapService.regionalStoriesMarkerCollection

  constructor() {
    this.analyticsService.sendScreenViewEvent(this.constructor.name, this.constructor.name)
  }

  onMapClick(event: MapMouseEvent) {
    /**
     * TODO: validate event data with zod interface
     * event.lngLat
     */
    this.mapClick.emit(event.lngLat)
    this.pinMarkerPoint.set({
      type: "Point",
      coordinates: [event.lngLat.lng, event.lngLat.lat],
    })
  }

  onContentMarkerClick(event: MapLayerMouseEvent) {
    /**
     * Validate event data with zod interface
     * event.features[0].properties as StoryProperties
     */
    const storyProperties = event.features?.[0]?.properties as StoryProperties | undefined;
    if (storyProperties) {
      this.contentMarkerClick.emit(storyProperties);
    }
  }

  onRegionClick(event: MapLayerMouseEvent) {
    /**
     * Validate event data with zod interface
     * event.lngLat
     * event.features?.[0].properties as RegionProperties
     */
    const lngLat = event.lngLat;
    const properties = event.features?.[0].properties as RegionProperties | undefined;
    if (properties) {
      this.regionClick.emit({ lngLat, properties });
    }
  }

  loadMap(mapboxEvent: MapboxEvent<undefined> & EventData) {
    const map = mapboxEvent.target
    this.localMapService.setZoomLevel(mapboxEvent.target.getZoom())
    this.map = map
  }

  zoomEvent(event: EventData) {
    const zoomLevel = this.map?.getZoom()
    if (zoomLevel !== undefined) {
      this.zoomLevelChange.emit(zoomLevel)
      // this.localMapService.setZoomLevel(zoomLevel)
    }
  }

}
