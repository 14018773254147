import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { Profile } from "./profile.model"
import { FirebaseAuthProfileService } from "../../data-access/firebase-auth-profile.service"

@Injectable({
  providedIn: "root",
})
export class ProfileEditorService {
  private firebaseAuthProfileService = inject(FirebaseAuthProfileService)

  private _profileEditor = signal<Profile | undefined>(undefined)
  profileEditor = this._profileEditor.asReadonly()
  autosave = signal(false)

  constructor() {
    effect(() => {
      const profile = this.profileEditor()
      untracked(() => {
        if (this.autosave()) {
          this.firebaseAuthProfileService.saveQueue.set(profile)
        }
      })
    })
    effect(() => {
      const profile = this.firebaseAuthProfileService.saveQueue()
      untracked(() => {
        if (profile) {
          this.firebaseAuthProfileService.saveProfile(profile)
        }
      })
    })
  }

  loadProfileEditor(profile: Profile | undefined) {
    this._profileEditor.set(profile)
  }


  saveToProfile(profile: Profile | undefined, autosave: boolean) {
    this.autosave.set(autosave)
    this.firebaseAuthProfileService.preSaving.set(autosave)
    this._profileEditor.set(profile)
  }

  saveProfile() {
    const profile = this.profileEditor()
    if (profile) {
      this.firebaseAuthProfileService.saveProfile(profile)
    }
  }

}
