import { Component, computed, effect, inject, input, signal, untracked } from "@angular/core"
import { faCheck, faYinYang } from "@fortawesome/pro-solid-svg-icons"
import { debounceSignal } from "@ddtmm/angular-signal-generators"
import { animate, state, style, transition, trigger } from "@angular/animations"
import { UiIconComponent } from "ui/icon"
import { FirebaseAuthProfileService } from "../../data-access/firebase-auth-profile.service"

@Component({
  imports: [
    UiIconComponent,
  ],
  standalone: true,
  animations: [
    trigger("fadeOut", [
      state(
        "in",
        style({
          opacity: 1,
          transform: "scale(1)",
        }),
      ),
      transition(":leave", [
        style({
          opacity: 1,
          transform: "scale(1)",
        }),
        animate(
          "1800ms",
          style({
            opacity: 0,
            transform: "scale(0)",
          }),
        ),
      ]),
    ]),
  ],
  template: `
    @if (enabled()) {
      @if (preSaving_local() && !saved()) {
        <lib-ui-icon
          [icon]="faYinYang"
          [style.font-size.px]="15"
          [style.color]="'green'"
          animation="spin"
        />
      }
      @if (saving() && !saved()) {
        <lib-ui-icon
          [icon]="faYinYang"
          [style.font-size.px]="15"
          [style.color]="'green'"
          animation="fade"
        />
      }
      @if (saved()) {
        <div style="display: inline-block" [@fadeOut]="'in'">
          <lib-ui-icon
            [style.transform-origin]="'top right'"
            [icon]="faCheck"
            [style.color]="'green'"
          />
        </div>
      }
    }
  `,
  selector: "e2e-profile-save-icon",
})
export class ProfileSaveIconComponent {
  private firebaseAuthProfileService = inject(FirebaseAuthProfileService)

  modelChange = input<boolean | string | undefined | number>(undefined)

  enabled = signal(false)
  preSaving_local = signal(false)
  saved = signal(false)
  saving = computed(() => !!this.firebaseAuthProfileService.saveCount())
  disableWithDelay = debounceSignal(0, 3000)

  constructor() {
    effect(() => {
      const preSaving = this.firebaseAuthProfileService.preSaving()
      if (preSaving) {
        untracked(() => {
          this.preSaving_local.set(true)
        })
      }
    })
    effect(() => {
      // console.log("saveCount", this.saveCount())
      if (this.saving()) {
        untracked(() => {
          this.preSaving_local.set(false)
          // this.profileDataAccessService.preSaving.set(false)
        })
      }
    })
    effect(() => {
      // console.log("modelChange", this.modelChange())
      if (this.modelChange() !== undefined) {
        untracked(() => this.restartAnimation())
      }
    })
    effect(() => {
      // console.log("preSaving", this.preSaving())
      // console.log("saveCount", this.saveCount())
      if (!this.preSaving_local() && !this.saving()) {
        untracked(() => {
          this.saved.set(true)
          this.disableWithDelay.set(Date.now())
        })
      }
    })

    // effect(() => console.log("enabled", this.enabled()))
    // effect(() => console.log("preSaving", this.preSaving()))
    // effect(() => console.log("saving", this.saving()))
    // effect(() => console.log("saved", this.saved()))
    effect(() => {
      // console.log("disableWithDelay", this.disableWithDelay())
      if (this.disableWithDelay()) {
        untracked(() => this.enabled.set(false))
      }
    })
  }

  restartAnimation() {
    this.disableWithDelay.set(0)
    this.enabled.set(true)
    if (!this.saving()) {
      // this.preSaving.set(true)
    }
    this.saved.set(false)
  }

  protected readonly faYinYang = faYinYang
  protected readonly faCheck = faCheck
}
